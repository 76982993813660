import React from "react";


export const SF_YEAR = 2025;


export const numberFormat = number => (isNaN(number) ? "" : new Intl.NumberFormat().format(Math.round(number)));

export const startTimeFormat = s => s.replace(/^.*?(\d{2}:\d{2}:\d{2}).+$/, '$1');

export const durationFormat = ms => new Intl.NumberFormat().format(ms / 1000, {});

export const zeroPad = num => (num <= 9 ? "0" + num : num);

export const getEndPoint = () => (window.location.hostname === "localhost") ? 'http://localhost:8000/admin' : '/admin';

export const getWebSocketEndPoint = () => (window.location.hostname === "localhost") ? 'ws://localhost:8000/admin' : 'wss://www.sf-teamradio.net/admin';

export const getObjectStorageEndPoint = () => { return "https://s3.isk01.sakurastorage.jp/sf-teamradio"; }

export const getDriverNameRows = (driver_name) => {
  const r = /^(\S+)(?:\s+(.+))?/;
  const e = r.exec(driver_name);

  const rows = [ e[1], e[2] || "　" ];
  return rows.map((s, i) => <span key={i}>{
            s.toUpperCase()}</span>);
}

export const getClipSequence = (clip) => {
  if (!clip.seq_no) {
    return null;
  }
  const seq_no = clip.seq_no;
  // const seq = seq_no >= 100 ? String.fromCharCode(seq_no / 10 + 55) + seq_no % 10 : zeroPad(seq_no);
  const seq = zeroPad(seq_no);
  return zeroPad(clip.car_no) + "-" + seq;
}

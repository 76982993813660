import React, {useState} from "react";
import Article from "../common/Article";

const Setting = (props) => {
  const [round, setRound] = useState(0);
  const [section, setSection] = useState("Development");
  const [title, setTitle] = useState("");

  const [pastRound, setPastRound] = useState("");
  const [pastTimelineId, setPastTimelineId] = useState(0);

  const currentTimeline = props.timeline.list.filter(timeline => (timeline.capture_status==="active" || timeline.capture_status==="inactive"));
  const pastTimeline = props.timeline.list.filter(timeline => (timeline.capture_status==="terminated" && timeline.round_id == pastRound));

  return (
    <>
      <div className="setting authentication">
      <Article className="content" {...props}>


        <div className="navigation">

          <h3 className="headline">SETTING</h3>

          { (currentTimeline.length > 0) && (
            <div className="navigation__unit">
              <h4>CURRENT TIME-LINE</h4>
              { currentTimeline.map(t => {
                return (
                  <div className="input-group" key={t.timeline_id}>
                    <div className="row g-4 gy-5 align-items-end row-cols-2 row-cols-md-4">
                      <div className="col-auto"><label className="form-label">{t.year} ROUND {t.round_id} - {t.section_name.toUpperCase()} {t.title} ({t.capture_status.toUpperCase()})</label>
                        <button type="button" className="basic-button submit-button t-hover" onClick={() => {
                    props.handleChangeScreen('monitoring', t.timeline_id);
                  }} ><span>GO</span></button>
                      </div>
                    </div>
                    <div className="valid-feedback"></div>
                  </div>
                );
              }
            )}
            </div>
          )}


          <div className="navigation__unit">
            <h4>CREATE TIME-LINE</h4>
            <div className="input-group">
              <div className="row g-4 gy-5 align-items-end row-cols-2 row-cols-md-4">
                <div className="col-auto"><label className="form-label">SELECT ROUND</label><select
                  className="select-box" value={round} onChange={event => setRound(event.target.value)}>
                  <option value="">NOT SELECTED</option>
                  <option value="0">TEST RUN (ROUND 0)</option>
                  <option value="1">ROUND 1 (2025.3 SUZUKA)</option>
                  <option value="2">ROUND 2 (2025.3 SUZUKA)</option>
                  { /*
                  <option value="1">ROUND 1 (2025.3 SUZUKA)</option>
                  <option value="2">ROUND 2 (2024.5 AUTOPOLIS)</option>
                  <option value="3">ROUND 3 (2024.6 SUGO)</option>
                  <option value="4">ROUND 4 (2024.7 FSW)</option>
                  <option value="5">ROUND 5 (2024.8 MOTEGI)</option>
                  <option value="6">ROUND 6 (2024.10 FSW)</option>
                  <option value="7">ROUND 7 (2024.10 FSW)</option>
                  <option value="8">ROUND 8 (2024.11 SUZUKA)</option>
                  <option value="9">ROUND 9 (2024.11 SUZUKA)</option>
*/}
                </select></div>
                <div className="col-auto"><label className="form-label">TIME-LINE TYPE</label><select
                  className="select-box" value={section} onChange={event => setSection(event.target.value)}>
                  <option value="Development">DEVELOPMENT</option>
                  <option value="Connection">CONNECTION</option>
                  <option value="Free Practice">FREE PRACTICE</option>
                  <option value="Qualifying">QUALIFYING</option>
                  <option value="Race">RACE</option>
                  <option value="Other Events">OTHER EVENTS</option>
                </select></div>
                <div className="col"><label className="form-label">TIME-LINE NAME</label><input type="text"
                                                                                                className="form-control"
                                                                                                placeholder="INPUT TIME-LINE NAME"
                value={title}
                      onChange={event => {
                        setTitle(event.target.value);
                      }} />
                </div>
                <div className="col-auto">
                  <button type="button" className="basic-button submit-button t-hover" onClick={() => props.handleCreate(round, section ,title)}><span>CREATE</span></button>
                </div>
              </div>
              <div className="valid-feedback">ERROR "TIME-LINE NAME" IS NOT ENTERED. , "TIME-LINE STATUS" IS NOT
                SELECTED.
              </div>
            </div>
          </div>



          <div className="navigation__unit">
            <h4>GO TO PAST TIME-LINE</h4>
            <div className="input-group">
              <div className="row g-4 gy-5 align-items-end">
                <div className="col-auto"><label className="form-label">SELECT ROUND</label><select
                  className="select-box" value={pastRound} onChange={event => setPastRound(event.target.value)}>
                  <option value="">NOT SELECTED</option>
                  <option value="0">TEST RUN (ROUND 0)</option>
                  <option value="1">ROUND 1 (2025.3 SUZUKA)</option>
                  <option value="2">ROUND 2 (2025.3 SUZUKA)</option>
                  { /*
                  <option value="1">ROUND 1 (2024.3 SUZUKA)</option>
                  <option value="2">ROUND 2 (2024.5 AUTOPOLIS)</option>
                  <option value="3">ROUND 3 (2024.6 SUGO)</option>
                  <option value="4">ROUND 4 (2024.7 FSW)</option>
                  <option value="5">ROUND 5 (2024.8 MOTEGI)</option>
                  <option value="6">ROUND 6 (2024.10 FSW)</option>
                  <option value="7">ROUND 7 (2024.10 FSW)</option>
                  <option value="8">ROUND 8 (2024.11 SUZUKA)</option>
                  <option value="9">ROUND 9 (2024.11 SUZUKA)</option>
                  */}
                </select></div>
                <div className="col-auto"><label className="form-label">SELECT TIME LINE</label><select
                  className="select-box" value={pastTimelineId} onChange={event => setPastTimelineId(event.target.value)}>
                  <option>NOT SELECTED</option>
                  {pastTimeline.map((t, i) => {
                    return (
                      <option key={i} value={t.timeline_id}>{t.section_name.toUpperCase()} {t.title} ({t.capture_status.toUpperCase()})</option>
                    );
                  })}
                </select></div>
                <div className="col-auto">
                  <button type="button" className="basic-button submit-button t-hover" onClick={() => {
                    pastTimelineId && props.handleChangeScreen('monitoring', pastTimelineId);
                  }} ><span>GO</span></button>
                </div>
              </div>
              <div className="valid-feedback">ERROR "TIME-LINE" IS NOT SELECTED.</div>
            </div>
          </div>



          <div className="back-button-area">
            <button className="back-button t-hover" onClick={props.handleBack}><span>BACK</span></button>
          </div>


        </div>


      </Article>
      </div>
    </>
  );
}

export default Setting;

import React from "react";
import TimelineOperation from "./TimelineOperation";
import BlockOperation from "./BlockOperation";
import EditTextModal from "../monitoring/EditTextModal";
import OperationModal from "./sub/OperationModal";
import {getCue, crossFadeShrink, applyBeep, findCutList, findCutList2} from "../../lib/sound";

const audioCtx = new AudioContext();

class OperationController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'block',
      wideOperationModal: false,
      autoSilenceDetection: true,
      customClipFilter: false,
      selectedCarNo: null,
      block: [],
      lockOnList: [],
      playingClips: {},
      draggingState: {},
      virtualTime: null,
      virtualStartTime: null,
      virtualCurrentTime: null,
      visitedAutoclipTable: {},
      clipBuffer: null,
      clipBufferLoaded: false,
    };
  }

  componentDidMount() {
    this.timerHandle = setInterval(() => {
      this.updateBlock();
    }, 450);
    this.operationStartTime = new Date();
  }

  componentWillUnmount() {             // ***
    if (this.timerHandle) {                  // ***
        clearTimeout(this.timerHandle);      // ***
        this.timerHandle = 0;                // ***
    }
    Object.keys(this.state.playingClips).forEach(k => { this.deletePlayingClip(k)});
    this.clearPlayingStateTimer();
  }

  clearPlayingStateTimer() {
    if (this.playStateHandle) {                  // ***
      clearInterval(this.playStateHandle);      // ***
      this.playStateHandle = 0;                // ***
    }
  }

  hasLockOnList(car_no) {
    return this.state.lockOnList.find(car => (car.car_no === car_no));
  }

  lockOnCar(car_no) {
    const lockOnList = this.state.lockOnList.slice();
    if (!this.hasLockOnList(car_no)) {
      const lockOnCar = {
        car_no: car_no,
        autoPlay: false,
        locked: true,
        clipList: [],
        currentClipListIndex: 0,
        startAutoClipId: 0,
        autoPlayClipId: 0,
        content : {
          cueStart: 0,
          cueEnd: 1,
          editInfo: {
            beepList: []
          },
        }
      }
      const car = this.props.timeline.current.cars.find(car => car.car_no == car_no);

      if (car.clips.length) {
        lockOnCar.startAutoClipId = car.clips[0].autoclip_id;
      }

      // test
      //lockOnCar.startAutoClipId = 1;

      lockOnList.push(lockOnCar);
      while (lockOnList.length > 4) {
        lockOnList.shift();
      }
      this.setState({lockOnList: lockOnList});
    }
  }

  unlockOnCar(car_no) {
    const lockOnList = this.state.lockOnList.slice(0);
    if (this.hasLockOnList(car_no)) {
      this.setState({lockOnList: lockOnList.filter(car => (car_no !== car.car_no))});
      const playingClips = this.state.playingClips;
      this.stopClipOfCar(car_no);
    }
  }

  handleClickDriver(car) {
    const car_no = car.car_no;
    if (this.hasLockOnList(car_no)) {
      this.unlockOnCar(car_no);
    } else {
      this.lockOnCar(car_no);
    }
  }

  handleChangeAutoPlay(car_no, flag) {
    const lockOnList = this.state.lockOnList.slice();
    const index = lockOnList.findIndex(lockOn => lockOn.car_no == car_no);

    const car = this.props.timeline.current.cars.find(car => car.car_no == car_no);
    const lockOn = Object.assign({}, lockOnList[index], {autoPlay: flag, autoPlayClipId: (car.clips.length ? car.clips[0].autoclip_id : 0), locked: true});
    lockOnList[index] = lockOn;
    this.setState({lockOnList: lockOnList});
  }

  handleChangeLockOnClipIndex(car_no, clipIndex) {
    const lockOnList = this.state.lockOnList.slice();
    const index = lockOnList.findIndex(lockOn => lockOn.car_no == car_no);

    if (clipIndex < lockOnList[index].clipList.length) {
      const lockOn = Object.assign({}, lockOnList[index], {locked: true, currentClipListIndex: clipIndex, autoPlay: false,
        content : {
          cueStart: 0,
          cueEnd: 1,
          editInfo: {
            beepList: []
          },
        }
      });
      lockOnList[index] = lockOn;
      this.stopClipOfCar(car_no);
      this.setState({lockOnList: lockOnList});
    }
  }

  handleChangeLockOnClipTransmitLock(car_no, flag) {
    const lockOnList = this.state.lockOnList.slice();
    const index = lockOnList.findIndex(lockOn => lockOn.car_no == car_no);
    const lockOn = Object.assign({}, lockOnList[index], {locked: flag});
    lockOnList[index] = lockOn;
    this.setState({lockOnList: lockOnList});
  }

  updateBlock() {
    if (!this.props.timeline) {
      return;
    }
    const t = this.props.timeline.current;
    if (!t || !t.cars) {
      return;
    }

    if (this.props.demoSeconds && !this.state.virtualTime) {
      const t = this.props.timeline.current;
      let virtualTime;
      const clipLastCreateTime = t.cars.map(
        car => car.clips.length ? car.clips.reduce((ac, cv) => { const c = new Date(cv.create_datetime); return ((ac && ac > c) ? ac : c)}, null) : null)
        .filter(c => c)
        .reduce((ac, cv) => ((ac && ac > cv) ? ac : cv), null);

      if (clipLastCreateTime) {
        virtualTime = new Date(clipLastCreateTime);
        virtualTime.setSeconds(clipLastCreateTime.getSeconds() - this.props.demoSeconds);
      } else {
        virtualTime = new Date();
      }

      this.setState({virtualTime: virtualTime, virtualStartTime: new Date()});
    }

    if (this.state.virtualTime) {
      const virtualSec = (new Date() - this.state.virtualStartTime) / 1000;
      const virtualCurrentTime = new Date(this.state.virtualTime);
      virtualCurrentTime.setSeconds(virtualCurrentTime.getSeconds() + virtualSec);
      this.setState({virtualCurrentTime: virtualCurrentTime});
    } else {
      this.setState({virtualCurrentTime: null});
    }

    const block = t.cars.map((car, i) => {
      const lastBlockItem = this.state.block[i];
      let hotClips = [];
      let historyClips = [];
      car.clips.forEach(clip => {
        const d = this.state.virtualCurrentTime ? this.state.virtualCurrentTime : new Date();
        const cd = new Date(clip.create_datetime);
        if (cd > d) {
          return;
        }
        if ((d - cd) / 1000 / 60 <= 3 && hotClips.length < 3) {
          hotClips.push({clip: clip, isHot: (cd > (this.state.virtualTime ? this.state.virtualTime : this.props.screenChangeTime))});
        } else {
          historyClips.push(clip);
        }
      });
      if (hotClips.length < 3) {
        hotClips = hotClips.concat(new Array(3 - hotClips.length).fill(null));
      }
      return Object.assign(
        {},
        (({ car_no, engine_code, team_code, driver_code, driver_name, capture_status }) => ({ car_no, engine_code, team_code, driver_code, driver_name, capture_status }))(car),
        {
          maxAutoclipId: car.clips.length > 0 ? car.clips[0].autoclip_id : 0,
          maxClipStartSample: car.clips.length > 0 ? car.clips[0].clip_start_sample : 0,
          hotClips: hotClips,
          historyClips: historyClips,
          last_process: car.last_process,
          last_chunk: car.last_chunk,
        });
      });
    const lockOnList = this.state.lockOnList.map(lockOn => {
      const newLockOn = Object.assign({}, lockOn);
      const car_no = lockOn.car_no;
      const car = t.cars.find(car => car.car_no === lockOn.car_no);
      newLockOn.clipList = car.clips.filter(clip => clip.autoclip_id > newLockOn.startAutoClipId).reverse();
      if (lockOn.autoPlay && newLockOn.clipList.length) {
        const newestClip = newLockOn.clipList.slice(-1)[0];
        if (newestClip.autoclip_id > newLockOn.autoPlayClipId) {
          newLockOn.autoPlayClipId = newestClip.autoclip_id;
          this.playClip(newestClip);
        }
        if (newLockOn.currentClipListIndex !== newLockOn.clipList.length - 1) {
          newLockOn.currentClipListIndex = newLockOn.clipList.length - 1;
          newLockOn.locked = true;
        }
      }
      return newLockOn;
    });

    this.setState({block: block, lockOnList: lockOnList});
  }

  backToMenu(e) {
    e.preventDefault();
    this.props.backToMenu();
  }

  handleClose() {
    this.props.handleClose();
    if (this.source) {
      this.source.stop();
    }
  }

  addPlayingClip(clip, speed, buffer, source, content = {cueStart: 0, cueEnd: 1}) {
    const playingClips = Object.assign({}, this.state.playingClips);
    const clip_id = clip.is_finalized ? ('c' + clip.customclip_id) : clip.autoclip_id;
    const cueRatio = 1 - content.cueStart - (1 - content.cueEnd);
    const cutList = content?.editInfo?.beepList ?
      content?.editInfo.beepList.filter(beep => beep.type === 'cut')
      .map(beep => ({start: (beep.start - content.cueStart) / cueRatio, end: (beep.end - content.cueStart) / cueRatio}))
        .sort((a, b) => a.start - b.start)
      : [];

    playingClips[clip_id] = {
      clip: clip,
      source: source,
      speed: speed,
      duration: clip.duration_ms / 1000 * cueRatio / speed,
      original_duration: clip.duration_ms / 1000,
      startTime: new Date(),
      cueStart: content.cueStart,
      cueEnd: content.cueEnd,
      progress: content.cueStart,
      editInfo: content.editInfo,
      cutList: cutList,
    };
    this.setState({playingClips: playingClips});
      if (!this.playStateHandle && Object.keys(playingClips).length) {
      this.playStateHandle = setInterval(() => {
        this.updatePlayingState();
      }, 100);
    }
  }

  stopClip(clip_id) {
    this.deletePlayingClip(clip_id);
  }

  stopAllClip() {
    const clip_id_list = Object.keys(this.state.playingClips);
    clip_id_list.forEach(clip_id => { this.stopClip(clip_id) })
  }

  stopClipOfCar(car_no) {
    const playingClips = this.state.playingClips;
    Object.keys(playingClips).forEach(k => { if (playingClips[k].clip.car_no === car_no) { this.deletePlayingClip(k)}});
  }

  deletePlayingClip(clip_id) {
    this.updatePlayingState();
    const playingClips = Object.assign({}, this.state.playingClips);
    if (playingClips.hasOwnProperty(clip_id)) {
      playingClips[clip_id].source.stop();
      delete playingClips[clip_id];

      this.setState({playingClips: playingClips});
    }

    if (this.playStateHandle && !Object.keys(playingClips).length) {
      this.clearPlayingStateTimer();
    }
  }

  updatePlayingState() {
    const playingClips = Object.assign({}, this.state.playingClips);
    const newPlayingClips = {};
    Object.keys(playingClips).forEach(clipId => {
      const c = Object.assign({}, playingClips[clipId]);
      c.progress = Math.min((new Date() - c.startTime) / 1000 / c.duration, 1);
      //console.log(c.progress);
      //console.log(c.cutList);
      if (c?.cutList) {
        c.cutList.forEach(cut => {
          if (c.progress >= cut.start) {
            c.progress += cut.end - cut.start;
          }
        });
      }
      c.progress = c.progress * (c.cueEnd - c.cueStart) + c.cueStart;
      if (c.progress > c.cueEnd) {
        c.progress = c.cueEnd;
      }

      newPlayingClips[clipId] = c;
    });
    this.setState({playingClips: newPlayingClips});
  }

  loadClipBuffer(clip) {
    const clip_id = clip.is_finalized ? ('c' + clip.customclip_id) : clip.autoclip_id;

    this.props.handleRequestClip(clip_id, res => { this.setState({clipBuffer: res.data, clipBufferLoaded: true})});
  }

  initClipBuffer(data) {

  }

  playClipFromBuffer(clip, speed=1, content = {cueStart: 0, cueEnd: 1}) {
    if (!this.state.clipBufferLoaded) {
      return false;
    }

    const clip_id = clip.is_finalized ? ('c' + clip.customclip_id) : clip.autoclip_id;
    const clipBuffer = this.state.clipBuffer.slice();
    this.deletePlayingClip(clip.clip_id);
    this.playClipStart(clipBuffer, clip, speed, content);
  }


  playClip(clip, speed=1, content = {cueStart: 0, cueEnd: 1}) {
    const clip_id = clip.is_finalized ? ('c' + clip.customclip_id) : clip.autoclip_id;

    this.deletePlayingClip(clip.clip_id);

    this.props.handleRequestClip(clip_id, res => {

      audioCtx.decodeAudioData(
        res.data,
        (buffer) => {
          const bufferLength = buffer.length;

          this.source = audioCtx.createBufferSource();
          if (content.editInfo && content.editInfo.beepList) {
            buffer = applyBeep(buffer, content.editInfo.beepList);
          }

          if (speed > 1) {
            buffer = crossFadeShrink(buffer, speed);
          }
          if (content.cueStart !== 0 || content.cueEnd !== 1) {
            buffer = getCue(buffer, content.cueStart * bufferLength, content.cueEnd * bufferLength);
          }
          if (!buffer) {
            return false;
          }
          this.source.buffer = buffer;
          this.source.connect(audioCtx.destination);
          this.source.start(0);
          this.source.onended = () => {
            this.deletePlayingClip(clip_id);
          };
          this.addPlayingClip(clip, speed, buffer, this.source, content);
        },
        (error) => {
            console.error('decodeAudioData error', error);
        }
      );
    });
  }


  smartPlayClip(clip, speed=1, content = {cueStart: 0, cueEnd: 1}) {
    const clip_id = clip.is_finalized ? ('c' + clip.customclip_id) : clip.autoclip_id;
    console.log(clip_id)
    this.deletePlayingClip(clip.clip_id);

    this.props.handleRequestClip(clip_id, res => {
      this.playClipStart(res.data, clip, speed, content);
    }, true);
  }

  playClipStart(clipBuffer, clip, speed, content) {
    const clip_id = clip.is_finalized ? ('c' + clip.customclip_id) : clip.autoclip_id;
    audioCtx.decodeAudioData(
        clipBuffer,
        (buffer) => {
          const bufferLength = buffer.length;

          this.source = audioCtx.createBufferSource();
          if (content.editInfo && content.editInfo.beepList) {
            buffer = applyBeep(buffer, content.editInfo.beepList);
          }

          if (speed > 1) {
            buffer = crossFadeShrink(buffer, speed);
          }
          if (content.cueStart !== 0 || content.cueEnd !== 1) {
            buffer = getCue(buffer, content.cueStart * bufferLength, content.cueEnd * bufferLength);
          }
          if (!buffer) {
            return false;
          }
          this.source.buffer = buffer;
          this.source.connect(audioCtx.destination);
          this.source.start(0);
          this.source.onended = () => {
            this.deletePlayingClip(clip_id);
          };
          this.addPlayingClip(clip, speed, buffer, this.source, content);
        },
        (error) => {
            console.error('decodeAudioData error', error);
        }
      );
  }

  changeViewMode(mode) {
    this.setState({mode: mode});
  }

  changeSilenceDetectionMode() {
    this.setState(prevState => ({autoSilenceDetection: !prevState.autoSilenceDetection}))
  }

  startMoveLockOnCue(state) {
    const car_no = state.car_no;
    this.handleChangeAutoPlay(car_no, false);
    this.handleChangeLockOnClipTransmitLock(car_no, true);

    this.setDraggingState(state);
    this.moveLockOnCue(car_no, state.startX, state.target);
    this.stopClipOfCar(car_no);
  };

  setDraggingState(state) {
    this.setState({draggingState: state});
  }

  clearDraggingState = () => {
    this.setDraggingState({target: null, startX: 0});
  }

  moveLockOnCue(car_no, x, target = null) {


    this.handleChangeAutoPlay(car_no, false);

    if (!target) {
      target = this.state.draggingState.target;
    }
    //this.stopClipOfCar(car_no);

    const lockOnList = this.state.lockOnList.slice();
    const index = lockOnList.findIndex(lockOn => lockOn.car_no == car_no);

    const content = Object.assign({}, lockOnList[index].content);
    const prefix = 'lockon-' + car_no;
    const cue = document.getElementById(prefix + '-cue');
    const cueInner = document.getElementById(prefix + '-cue-inner');
    const gap = (cue.clientWidth - cueInner.clientWidth) / 2 * (target === 'start' ? 1 : -1);
    let pos = ((x - cueInner.getBoundingClientRect().left + gap / 2) / cueInner.clientWidth);
    let leftMax = Math.max(content.cueStart, 0.1);
    let rightMax = Math.min(content.cueEnd, 0.9);
    if (target === 'start') {
      if (pos < 0 ) {
        pos = 0;
      }
      if (pos > rightMax) {
        pos = rightMax;
      }
      content.cueStart = pos;
      lockOnList[index] = { ...lockOnList[index], content: content};
      console.log(lockOnList);
      this.setState({lockOnList: lockOnList});
    } else if (target === 'end') {
      if (pos > 1) {
        pos = 1;
      }
      if (pos < leftMax) {
        pos = leftMax;
      }
      content.cueEnd = pos;
      lockOnList[index] = { ...lockOnList[index], content: content};
      this.setState({lockOnList: lockOnList});
    }
  }

  setLockOnCue(car_no, cue = {start: 0, end: 1}) {
    const lockOnList = this.state.lockOnList.slice();
    const index = lockOnList.findIndex(lockOn => lockOn.car_no == car_no);
    const content = {
      cueStart: cue.start,
      cueEnd: cue.end,
    };
    lockOnList[index] = { ...lockOnList[index], content: content};
    this.setState({lockOnList: lockOnList});
  }

  handleTransmit() {
    const { source } = this.props.operationClip;
    this.handleCustomClipFilterChange(false);
    this.props.handleTransmit(source);
    // Create Custom Clip
    this.handleClose();
  }

  handleDirectTransmit(clip, content) {
    this.props.handleDirectTransmit(clip, content);
    //this.props.handleChangeLockOnClipTransmitLock(clip.car_no, true)
  }

  handleSave() {
    this.props.handleSaveCustomClip();
    // this.handleClose();
  }

  handleDelete() {
    this.props.handleDeleteCustomClip();
  }

  handleCustomClipFilterChange(flag) {
    this.setState({customClipFilter: flag});
  }

  handleEditOperation(clip, content = null) {
    this.props.handleEditOperation(clip, content);
    if (!clip.customclip_id) {
      const visitedAutoclipTable = Object.assign({}, this.state.visitedAutoclipTable);
      visitedAutoclipTable[clip.autoclip_id] = true;
      this.setState({visitedAutoclipTable: visitedAutoclipTable});
    }
    if (this.state.autoSilenceDetection && !clip.customclip_id) {
      this.handleFindCutArea(clip)
    }
    this.setState({clipBuffer: null, clipBufferLoaded: false}, () => { this.loadClipBuffer(clip) });
  }

  handleFindCutArea(clip) {
    const clip_id = clip.is_finalized ? ('c' + clip.customclip_id) : clip.autoclip_id;

    this.deletePlayingClip(clip.clip_id);

    const useAudioFeature = true;
    if (useAudioFeature) {

      this.props.handleRequestAudioFeature(clip_id, res => {
        const startTime = performance.now();
        const cutList = findCutList2(res.data);
        const endTime = performance.now(); // 終了時間
        console.log(endTime - startTime); // 何ミリ秒かかったかを表示する

        cutList.forEach(pos => {
          this.props.handleAddBeep(pos, 'cut');
        })

      });
    } else {
      this.props.handleRequestClip(clip_id, res => {
        audioCtx.decodeAudioData(
          res.data,
          (buffer) => {
            const startTime = performance.now();
            const cutList = findCutList(buffer);
            const endTime = performance.now(); // 終了時間
            console.log(endTime - startTime); // 何ミリ秒かかったかを表示する
            cutList.forEach(pos => { this.props.handleAddBeep(pos, 'cut');})
          },
          (error) => {
              console.error('decodeAudioData error', error);
          }
        );
      });
    }
  }

  handleChangeWideOperationModal() {
      this.setState(prevState => ({wideOperationModal: !prevState.wideOperationModal}))
  }

  render() {
    const {block, lockOnList, playingClips, customClipFilter, autoSilenceDetection, visitedAutoclipTable, wideOperationModal} = this.state;
    const {loginUser, timeline, headerAccount, operationClip} = this.props;
    let operationComponent = <></>;
    if (this.state.mode === 'block') {
      operationComponent = <BlockOperation {...this.props}
        block={block}
        lockOnList={lockOnList}
        playingClips={playingClips}
        visitedAutoclipTable={visitedAutoclipTable}
        virtualCurrentTime={this.state.virtualCurrentTime}
        customClipFilter={customClipFilter}
        operationStartTime={this.operationStartTime}
        draggingState={this.state.draggingState}
        autoSilenceDetection={autoSilenceDetection}

        handleChangeViewMode={mode => { this.changeViewMode(mode); }}
        handleChangeSilenceDetectionMode={() => { this.changeSilenceDetectionMode(); }}

        handleCustomClipFilterChange={flag => { this.handleCustomClipFilterChange(flag); }}
        handleEditOperation={(clip, content = null) => { this.handleEditOperation(clip, content)}}

        handlePlayClip={(clip, speed, content = null) => { this.playClip(clip, speed, content)}}
        handleStopClip={(clip_id) => { this.stopClip(clip_id)}}
        handleTransmit={() => this.handleTransmit() }
        handleSave={() => this.handleSave() }

        handleChangeAutoPlay={(car_no, flag) => {this.handleChangeAutoPlay(car_no, flag);}}
        handleChangeLockOnClipIndex={(car_no, clipIndex) => {this.handleChangeLockOnClipIndex(car_no, clipIndex);}}
        handleChangeLockOnClipTransmitLock={(car_no, flag) => {this.handleChangeLockOnClipTransmitLock(car_no, flag);}}
        handleDirectTransmit={(clip, content) => { this.handleDirectTransmit(clip, content);}}

        handleClickDriver={car => { this.handleClickDriver(car); }}
        handleStartMoveLockOnCue={state => { this.startMoveLockOnCue(state)}}
        handleMoveLockOnCue={(car_no, x) => { this.moveLockOnCue(car_no, x)}}
        handleSetDraggingState={state => { this.setDraggingState(state)}}

        handleBack={e => this.backToMenu(e)}
      />;
    } else {
      operationComponent =  <TimelineOperation
        {...this.props}
        virtualCurrentTime={this.state.virtualCurrentTime}

        visitedAutoclipTable={visitedAutoclipTable}
        autoSilenceDetection={autoSilenceDetection}
        handleChangeViewMode={mode => { this.changeViewMode(mode); }}
        handleChangeSilenceDetectionMode={() => { this.changeSilenceDetectionMode(); }}
        handleCustomClipFilterChange={flag => { this.handleCustomClipFilterChange(flag); }}
        handleEditOperation={(clip, playStart = false) => { this.handleEditOperation(clip, playStart)}}

        handlePlayClip={(clip, speed) => { this.playClip(clip, speed)}}
        handleStopClip={(clip_id) => { this.stopClip(clip_id)}}
        handleTransmit={() => this.handleTransmit() }
        handleSave={() => this.handleSave() }

        handleDirectTransmit={(clip, content) => { this.handleDirectTransmit(clip, content);}}

        handleBack={e => this.backToMenu(e)}

      />;
    }
    return <>
          {operationClip && (
        <OperationModal
          {...this.props}
          wideOperationModal={wideOperationModal}
          clipBufferLoaded={this.state.clipBufferLoaded}
          operationClip={operationClip}
          playingClips={playingClips}
          handlePlayClip={(clip, speed, content) => { this.playClip(clip, speed, content)}}
          handleSmartPlayClip={(clip, speed, content) => { this.smartPlayClip(clip, speed, content)}}
          handlePlayClipFromBuffer={(clip, speed, content) => { this.playClipFromBuffer(clip, speed, content)}}

          handleStopClip={(clip_id) => { this.stopClip(clip_id)}}
          handleChangeWideOperationModal={() => this.handleChangeWideOperationModal() }
          handleFindCutArea={clip => { this.handleFindCutArea(clip); }}
          handleTransmit={() => this.handleTransmit() }
          handleSave={() => this.handleSave() }
          handleDelete={() => this.handleDelete() }
          handleClose={() => this.handleClose()}
        />
      )}
          {operationComponent}
    </>

  }
}

export default OperationController;
